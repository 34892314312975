import './App.css';
import { Footer } from './components/Footer';
import { Home } from './pages/Home';
import Header from './components/Header';
import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { AboutUs } from './pages/AboutUs';
import { ContactUs } from './pages/ContactUs';
import { Game } from './pages/Game';
import { PrivacyPolicy } from './pages/Policy';
import { Signup } from './pages/SignUp';
import { GameHeader } from './components/GameHeader';
import { SmallNavbar } from './components/SmallNavbar';
import usePersistQueryParams from './hooks/usePersistQueryParams';
import { Helmet } from 'react-helmet';

function App() {
  const location = useLocation();
  const currentRoute = location.pathname;
  usePersistQueryParams();

  return (
    <main>
      <div className="wrapper">

        {/* Helmet for handling metadata based on the route */}
        <Helmet>
          <title>
            {{
              '/': 'ShibaDragon - Home',
              '/about': 'ShibaDragon - About Us',
              '/contact': 'ShibaDragon - Contact Us',
              '/game': 'ShibaDragon - Game',
              '/policy': 'ShibaDragon - Privacy Policy',
              '/signup': 'ShibaDragon - Sign Up',
            }[currentRoute] || 'ShibaDragon'}
          </title>
          <meta name="description" content="Welcome to ShibaDragon! Dive into a premium betting experience with an array of slot and fishing-inspired mini-games. Enjoy games like Mr. Macau, Alien Fruits, and World of Lord Elf King as you place bets, earn rewards, and explore over 20 exciting games designed for thrilling gameplay. ShibaDragon delivers an engaging mobile betting experience packed with daily rewards!" />
          <meta name="keywords" content="ShibaDragon, betting app, mobile betting games, slot-inspired games, fishing games, Mr. Macau, Alien Fruits, World of Lord Elf King, gaming app, premium gaming, daily rewards, mobile betting, casual betting" />
          <meta name="author" content="Materuno" />
        </Helmet>

        {/* Conditional Header Starts */}
        {currentRoute === '/game' && <GameHeader />}
        {currentRoute === '/about' && <SmallNavbar />}
        {currentRoute !== '/game' && currentRoute !== '/about' && currentRoute !== '/policy' && currentRoute !== '/signup' && <Header />}
        {/* Conditional Header Ends */}

        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<AboutUs />} />
          <Route path='/contact' element={<ContactUs />} />
          <Route path='/game' element={<Game />} />
          <Route path='/policy' element={<PrivacyPolicy />} />
          <Route path='/signup' element={<Signup />} />
        </Routes>

        {/* Conditional Footer Starts */}
        {currentRoute !== '/policy' && <Footer />}
        {/* Conditional Footer Ends */}
      </div>
    </main>
  );
}

export default App;