// src/components/QRCodeComponent.js
import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import QRCode from 'qrcode.react';
import { isMobile } from 'react-device-detect';

const QRCodeComponent = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const param = queryParams.get('ref');

  const qrUrl = `https://shibadragon.app/signup?ref=${param}`;

  const createLink = (path) => `${path}?${queryParams.toString()}`;

  const canvasStyles = {
    border: 'solid 6px #e20b70',
    marginTop: '12px',
    boxShadow: '0px 0px 16px 0px rgba(226,11,112,1)',
  };

  return (
    <div className="qr-code">
      {param && !isMobile ? (
        <QRCode value={qrUrl} size={156} style={canvasStyles} />
      ) : (
        <Link style={{display: 'none'}} to={createLink('/signup')} className="signup-btn">
          <img src="/assets/images/signup.png" alt="signup" />
        </Link>
      )}
    </div>
  );
};

export default QRCodeComponent;