import React from "react";
import Navbar from "./Navbar";
import FullscreenIframe from './GameFrame';

export const GameHeader = () => {
  return (
    <div className="game-header" style={{ height: '100vh', width: '100vw', position: 'relative', backgroundColor: 'black' }}>
      <Navbar />
      <div className="container" style={{ height: 'calc(100% - 70px)', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '20px' }}>
        <div className="mobile-header d-none justify-content-between align-items-center pt-4">
          <img src="/assets/images/logo.png" height="70" width="158" className="mibile-logo" alt="logo" />
          
          
          <button className="btn p-0" type="submit" data-bs-toggle="modal" data-bs-target="#exampleModal">
            <img src="/assets/images/buttons/play.png" height="45" alt="" />
          </button>
          
        </div>
        <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <FullscreenIframe src="/build/" />
        </div>
        <div className="bottom-links text-center mt-5">
          <h3 className="text-light">Play On Mobile</h3>
          <div className="links mt-4">
            <a href=""><img src="assets/images/app.png" height="50" alt="" className="mb-3" /></a>
            <a href="" className="mx-3"><img src="assets/images/google.png" height="50" alt="" className="mb-3" /></a>
          </div>
        </div>
      </div>
    </div>
  );
};