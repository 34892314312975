import React from "react";
import { About } from '../components/About';
import { AppScreen } from '../components/AppScreen';
import { Contact } from '../components/Contact';
export const Home = () => {

    return (
        <>
            {/* About us starts */}
            <About />
            {/* About us ends */}

            {/* app screen starts */}
            <AppScreen />
            {/* app screen ends */}

            {/* Contact us starts */}
            <Contact />
            {/* contact us ends */}
        </>
    )
}