import React from "react";
import Navbar from "./Navbar";
import { NavLink, useLocation } from 'react-router-dom';


export const SmallNavbar = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    // Utility function to detect mobile devices
    const isMobileDevice = () => {
        return /Mobi|Android/i.test(navigator.userAgent);
    };

    const createLink = (path) => `${path}?${queryParams.toString()}`;

    // Determine the game link based on the device type
    const gameLink = isMobileDevice() ? createLink('/build') : createLink('/game');

    return (
        <div className="game-header small-game-header">
            <div className="container">
                <Navbar />
                <div className="mobile-header d-none justify-content-between align-items-center pt-4">
                    <img src="/assets/images/logo.png" height='70' width='158' className="mibile-logo" alt="logo" />
                    <NavLink to={gameLink} className="btn p-0">
                        <img src="/assets/images/buttons/play.png" height='42' alt="" />
                    </NavLink>
                </div>
            </div>
        </div>
    )
}