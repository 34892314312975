import React from 'react'
import { NavLink } from 'react-router-dom'

export const About = () => {
  return (
    <>
      <section className="about_wrapper py-5">
        <div className="container">
          <div className="about-content text-center">
            <h2>About Us</h2>
            <p className='py-3'>Welcome to ShibaDragon, a premium online betting app that brings together thrilling games and unforgettable experiences. At ShibaDragon, we’ve created a vibrant, immersive environment where players can dive into exciting slot-inspired games, adventurous fishing challenges, and more, all designed for pure entertainment and real rewards.
            </p>
            <p>With a wide variety of games to choose from, you can find something that suits every playstyle. Our platform is filled with engaging options, from classic slots and unique mini-games to social features that let you connect, compete, and climb the rankings. New users are greeted with a generous coin bonus, and daily opportunities like our Lucky Wheel keep the excitement alive every day.
            </p>
            <p className='pt-3'>At ShibaDragon, we’re committed to providing a safe, fair, and enjoyable gaming experience for all players. Whether you’re here for the thrill of the jackpot or to explore our diverse game library, ShibaDragon offers a one-of-a-kind mobile betting experience that goes beyond the ordinary. Join us, level up, and let the games begin!</p>
            <div className="about-btn mt-5">
              <NavLink className='btn border-0' to='/game'><img src="/assets/images/buttons/play.png" alt="" /></NavLink>
            </div>
          </div>
        </div>
        <div className="about-images">
          <div className="diamond-img">
            <img src="/assets/images/diamond.png" alt="" />
          </div>
          <div className="face-img">
            <img src="/assets/images/face.png" alt="" />
          </div>
          <div className="gold-img">
            <img src="/assets/images/gold.png" alt="" />
          </div>
        </div>
        <img src="/assets/images/diamond.png" className='sm-diamond' width='60' alt="" />
      </section>
    </>
  )
}
