import React, { useEffect, useState } from 'react';

const FullscreenIframe = ({ src }) => {
  const [iframeSize, setIframeSize] = useState({ width: '100%', height: '100%' });

  const calculateIframeSize = () => {
    const aspectRatio = 1700 / 840;
    const maxWidth = 1700; // Desired max width from your aspect ratio
    const maxHeight = 800; // Desired max height from your aspect ratio

    let width = window.innerWidth * 0.9; // Adjust width to 90% of window width
    let height = width / aspectRatio; // Calculate height based on aspect ratio

    // If height exceeds max height, adjust both width and height
    if (height > maxHeight) {
      height = maxHeight;
      width = height * aspectRatio;
    }

    // If width exceeds max width, adjust both width and height
    if (width > maxWidth) {
      width = maxWidth;
      height = width / aspectRatio;
    }

    setIframeSize({ width: `${width}px`, height: `${height}px` });
  };

  useEffect(() => {
    calculateIframeSize();
    window.addEventListener('resize', calculateIframeSize);

    return () => {
      window.removeEventListener('resize', calculateIframeSize);
    };
  }, []);

  return (
    <div style={{ 
      width: '100%', 
      height: '100%', 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center',
      backgroundColor: 'black' // optional for better visibility
    }}>
      <iframe 
        src={src} 
        style={{ 
          width: iframeSize.width, 
          height: iframeSize.height, 
          border: 'none' 
        }} 
        title="Fullscreen Iframe"
      ></iframe>
    </div>
  );
};

export default FullscreenIframe;