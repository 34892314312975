import React from 'react'

export const AppScreen = () => {
  return (
    <>
      <section className="app_wrapper">
        <div className="container">
          <div className="app-header text-center">
          <h2 className='pt-5 small-d-none'>App Screens</h2>
            <h2 className='pt-5 small-d-block'>Game Play</h2>
            <p className='mt-3'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aliquid, eius. Facere deleniti <br /> veritatis amet,  dolorem eum repellat itaque in autem?</p>
          </div>

        <div className="app-content zindex-100 mt-5">
            <img src="/assets/images/group-img.png" alt="" className='img-fluid' />
          </div>
        </div>
        
        <div className="app-face">
          <img src="/assets/images/face2.png" alt="" />
        </div>
        <img src="/assets/images/shadow.png" className='shadow' alt="" />
        <img src="/assets/images/shadow.png" className='left-shadow' alt="" />

      </section>
    </>
  )
}
