import React from "react";
import Navbar from "./Navbar";
import QRCodeGenerator from './QRCodeGenerator';
import { NavLink, useLocation } from 'react-router-dom';


const Header = () => {
  const displayPlayButton = true;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const param = queryParams.get('ref');

  // Utility function to detect mobile devices
  const isMobileDevice = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
  };

  const createLink = (path) => `${path}?${queryParams.toString()}`;

  // Determine the game link based on the device type
  const gameLink = isMobileDevice() ? createLink('/build') : createLink('/game');


  const handleImageClick = (url) => {
    window.location.href = url;
  };

  return (
    <section className="header">
      <div className="container">
        <Navbar />
        <div className="mobile-header d-none justify-content-between align-items-center pt-4">
          <img src="/assets/images/logo.png" height='70' width='158' className="mibile-logo" alt="logo" />
          <span className="beta-tag">Beta!</span>
          {displayPlayButton && (
            <a href={gameLink} className="btn p-0 d-none">
              <img src="/assets/images/buttons/play.png" height='42' alt="" />
            </a>
          )}
        </div>
        <div className="bottom-content mt-5 pt-5">
          <div className="scaner-card card border-0">
            <h2>Start play for exclusive Fishing, Slots and more!</h2>
            <span>SCAN ME TO PLAY NOW!</span>
            <div className="scaner-img">
              <QRCodeGenerator />
            </div>
            <div className="golden-img">
              <img src="/assets/images/gold1.png" alt="" />
            </div>
          </div>
          <div className="app-img d-flex">
            <img src="/assets/images/google.png" alt="" onClick={() => handleImageClick('https://download.shibadragon.app/android')} />
            <img src="/assets/images/app.png" alt="" className="ms-2" onClick={() => handleImageClick('https://download.shibadragon.app/ios')} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header;