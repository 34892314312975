import React, { useState } from 'react';
import { About } from '../components/About';
import { useSearchParams } from 'react-router-dom';
import Joi from 'joi';
import axios from 'axios';

const signupValidator = Joi.object({
    username: Joi.string().min(4).required(),
    name: Joi.string().max(255).required(),
    lastname: Joi.string().max(255).required(),
    email: Joi.string().email(({ tlds: { allow: false } })).max(255).required(),
    password: Joi.string().min(4).required()
});

export const Signup = () => {
  const [searchParams] = useSearchParams();
  const [formData, setFormData] = useState({ username: '', name: '', lastname: '', email: '', password: '' });
  const ref = searchParams.get('ref');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { error, value } = signupValidator.validate(formData);

    if (error) {
        alert(`Validation error: ${error.details[0].message}`);
        return;
    }

    const { username, name, lastname, email, password } = value;
    const data = { username, name, lastname, email, password };

    if (ref) {
        data.referrer = ref;
    }

    axios.post('https://gameservice-stg.shibadragon.app/auth/signup', data)
    .then(response => {
        alert('Signup successful!');
        window.location.href = '/';
    })
    .catch(error => {
        alert('There was an error signing up: ' + error.response.data.message);
    });
  };

  return (
    <>
    <div className={'header'}>
      <div className={'signup'}>
        <h2>Join the Treasure Hunt!</h2>
        <span>Fill in the details below to start your journey.</span>
        
        <form onSubmit={handleSubmit}>

          <label>Username</label>
          <input
            type="text"
            name="username"
            value={formData.username}
            onChange={handleChange}
            placeholder="Enter your username"
            required
          />

          <label>Name</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Enter your name"
            required
          />

          <label>Last Name</label>
          <input
            type="text"
            name="lastname"
            value={formData.lastname}
            onChange={handleChange}
            placeholder="Enter your last name"
            required
          />
          
          <label>Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Enter your email"
            required
          />
          
          <label>Password</label>
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            placeholder="Choose a password"
            required
          />

          <button type="submit"><img src="/assets/images/signup.png" alt="signup" /></button>
        </form>
      </div>
    </div>
    <About />
    </>
  );
};