import React from 'react'
import { NavLink } from 'react-router-dom'

export const About = () => {
  return (
    <>
      <section className="about_wrapper py-5">
        <div className="container">
          <div className="about-content text-center">
            <h2>About Us</h2>
            <p className='py-3'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis iure iusto voluptatibus nisi, pariatur fuga magnam delectus autem est consectetur esse cum voluptatum corrupti eos? Eligendi commodi quibusdam sapiente ipsa quam? Id asperiores nulla excepturi quibusdam totam minima adipisci delectus dolore tempore nisi, rerum, nemo at, voluptate cumque exercitationem consequuntur beatae enim facilis! Accusamus quo laudantium iste, in mollitia ducimus placeat</p>
            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Totam eaque in suscipit perferendis, harum laborum similique, cupiditate neque maiores alias distinctio praesentium modi quaerat aliquid! Ipsa facere iste, reiciendis id neque voluptas!</p>
            <p className='pt-3'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Obcaecati eius delectus quidem repellat aliquid iusto! Nihil, provident nesciunt sint nam odio eaque laudantium necessitatibus numquam modi, tempore quia aperiam corporis ex omnis distinctio quis earum animi recusandae eos odit consequuntur deleniti quidem.</p>
            <div className="about-btn mt-5">
              <NavLink className='btn border-0' to='/game'><img src="/assets/images/buttons/play.png" alt="" /></NavLink>
            </div>
          </div>
        </div>
        <div className="about-images">
          <div className="diamond-img">
            <img src="/assets/images/diamond.png" alt="" />
          </div>
          <div className="face-img">
            <img src="/assets/images/face.png" alt="" />
          </div>
          <div className="gold-img">
            <img src="/assets/images/gold.png" alt="" />
          </div>
        </div>
        <img src="/assets/images/diamond.png" className='sm-diamond' width='60' alt="" />
      </section>
    </>
  )
}
