import React from "react";
import { Contact } from '../components/Contact';
export const ContactUs = () => {

    return (
        <>

            {/* Contact us starts */}
            <Contact />
            {/* contact us ends */}
        </>
    )
}