import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

const Navbar = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const param = queryParams.get('ref');

  // Utility function to detect mobile devices
  const isMobileDevice = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
  };

  const createLink = (path) => `${path}?${queryParams.toString()}`;

  // Determine the game link based on the device type
  const gameLink = isMobileDevice() ? createLink('/build') : createLink('/game');

  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary pt-0">
      <div className="container-fluid">
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto w-100 mb-2 mb-lg-0">
            <li className="nav-item active-item me-4">
              <NavLink className="nav-link" aria-current="page" to={createLink('/')}>Home</NavLink>
            </li>
            <li className="nav-item me-4">
              <NavLink className="nav-link" aria-current="page" to={createLink('/about')}>About</NavLink>
            </li>
            {!param && (
              <li className="nav-item me-4">
                <NavLink className="nav-link" aria-current="page" to={createLink('/contact')}>Contact</NavLink>
              </li>
            )}
            <li className="nav-item w-100 text-center">
              <a className="nav-link" aria-current="page" href="#">
                <img src="/assets/images/logo.png" height='80' className="dekstop-logo" alt="logo" />
              </a>
            </li>
          </ul>
          <NavLink to={gameLink} className="btn p-0">
            <img src="/assets/images/buttons/play.png" height='42' alt="" />
          </NavLink>
        </div>
      </div>

      <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Register</h5>
              <button type="button" className="" data-bs-dismiss="modal" aria-label="Close">
                <i className="fa-regular fa-circle-xmark"></i>
              </button>
            </div>
            <div className="modal-body">
              <form action="">
                <div className="register-form p-3">
                  <div className="form-group mb-5">
                    <input type="text" className="form-control" placeholder="Username" autoComplete="off" />
                  </div>
                  <div className="form-group mb-5">
                    <input type="text" className="form-control" placeholder="Password" autoComplete="off" />
                  </div>
                </div>
                <div className="capcha mt-5 text-center pt-3">
                  <img src="/assets/images/capcha.png" alt="" />
                </div>
              </form>
            </div>
            <div className="modal-footer border-0 d-flex justify-content-center mb-4">
              <button type="button" className="btn border-0">
                <img src="/assets/images/buttons/register.png" alt="" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;