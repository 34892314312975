// src/hooks/usePersistQueryParams.js
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const usePersistQueryParams = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.toString()) {
      const handleClick = (event) => {
        const target = event.target.closest('a');
        if (target && target.href && !target.href.includes(queryParams.toString())) {
          event.preventDefault();
          navigate(target.pathname + target.search + '?' + queryParams.toString());
        }
      };

      document.addEventListener('click', handleClick);

      return () => {
        document.removeEventListener('click', handleClick);
      };
    }
  }, [location.search, navigate]);
};

export default usePersistQueryParams;